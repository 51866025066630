<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<BatchExport v-if="dialog.refresh2" :row="{ name: 1 }" @refresh="refreshDialog" />
		<el-card>
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="space-between">
							<el-col>
								<el-button v-if="
								        $hasBtn(
								            'sys:permissions:config:violation:add'
								        )
								    "
								 type="primary" size="mini" @click="
                                        openDialog({
                                            title: '添加',
                                            addOr: 'add',
                                        })
                                    ">添加</el-button>
								<el-button v-if="
								                $hasBtn(
								                    'sys:permissions:config:violation:batch:import'
								                )
								            "
								 type="warning" size="mini" @click="openExport">批量导入</el-button>
								<el-button v-if="
								                $hasBtn(
								                    'sys:permissions:config:violation:batch:delete'
								                )
								            "
								 type="danger" size="mini" @click="removeAll">批量删除</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getListNow">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="580" row-id="company_id" :sort-config="{ remote: true }"
				 :filter-config="{ remote: true }" :checkbox-config="{ reserve: true }" :data="table.list" :loading="loading"
				 @checkbox-all="handleSelectionChange" @checkbox-change="handleSelectionChange">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="checkType" title="检查类型" />
					<vxe-table-column title="操作" width="260">
						<template v-slot="{ row }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="
                                                $hasBtn(
                                                    'sys:permissions:config:violation:edit'
                                                )
                                            "
									 icon="el-icon-edit" @click.native="
                                            openDialog(
                                                {
                                                    title: '修改人员',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )
                                        ">修改</el-dropdown-item>
									<el-dropdown-item v-if="
                                                $hasBtn(
                                                    'sys:permissions:config:violation:delete'
                                                )
                                            "
									 icon="el-icon-delete" @click.native="
                                            remove({ del: 'single' }, row)
                                        ">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from "@/utils/tableStyleJs";
	import Operate from "./Operate";
	import BatchExport from "./BatchExport";
	export default {
		name: "Index",
		components: {
			Operate,
			BatchExport,
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: "",
					sortBy: "",
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					refresh3: false, // 每次刷新DOM
					refreshRole: false, // 每次刷新DOM
					show: false,
					show2: false,
					show3: false,
					showRole: false,
				},
				userIds: []
			};
		},
		created() {
			this.getList();
		},
		mounted() {},
		methods: {
			rowStyle,
			headerStyle,
			getListNow() {
				this.userIds = [];
				this.getList();
			},
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true;
				this.dialog.show = true;
				this.dialog.info = info;
				this.dialog.row = row;
			},
			// 打开批量导入
			openExport() {
				this.dialog.refresh2 = true;
				this.dialog.show2 = true;
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/archives/server/escort/web/physicalConditionConfig',
						null, {
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((res) => {
						this.loading = false;
					});
			},
			remove(info, row) {
				this.$confirm("确认删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/archives/server/escort/web/physicalConditionConfig/' + row.id,
							null,
							null,
							"delete"
						).then((res) => {
							this.$message.success(res.msg);
							this.getList();
						});
					})
					.catch(() => {
						this.$message.info("删除失败！！！");
					});
			},
			removeAll() {
				var that_ = this;
				if (that_.userIds.length < 1) {
					this.$message.error("请勾选需要删除的选项");
					return false;
				}
				this.$confirm("确认批量删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/archives/server/escort/web/physicalConditionConfig',
							that_.userIds,
							null,
							"delete"
						).then((res) => {
							//console.log(JSON.stringify(res))
							that_.$message.success("批量删除成功");
							that_.userIds = [];
							that_.getList();
						});
					})
					.catch(() => {
						that_.$message.info("取消了批量删除！！！");
					});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.getList();
			},
			refreshDialog() {
				this.dialog.refresh = false;
				this.dialog.refresh2 = false;
				this.dialog.refresh3 = false;
				this.dialog.refreshRole = false;
				this.getList();
			},
			handleSelectionChange(val) {
				const data = val.records;
				this.userIds = [];
				for (let i = 0; i < data.length; i++) {
					this.userIds.push(data[i].id);
				}
			},
		},
	};
</script>

<style scoped></style>
