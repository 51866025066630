<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="150px" class="allForm">
					<el-form-item label="类型" :required="true" class="formInput">
						<el-select v-model="form.violateType" class="w-100" @change="violateConfigChange">
							<el-option v-for="(v,i) in serviceType" :key="i" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item v-if="form.violateType == 1" label="事故类型" :required="true" class="formInput">
						<el-select v-model="form.accidentType" class="w-100">
							<el-option v-for="(v,i) in accidentTypeList" :key="i" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item label="事件">
						<el-input v-model="form.violateEvent" class="formInput" />
					</el-form-item>
					<el-form-item label="记分">
						<el-input type="number" @input="inputClick1" v-model="form.scoring" class="formInput" />
					</el-form-item>
					<el-form-item label="处罚">
						<el-input v-model="form.punish" class="formInput" />
					</el-form-item>
					<el-form-item label="扣减安全公里数">
						<el-input style="width: 90%;" type="number" @input="inputClick" v-model="form.deductSafeKilometers" class="formInput" />
						<span style="padding-left: 5px;">公里</span>
					</el-form-item>
					<el-form-item v-if="$admin" label="是否系统数据" :required="true" class="formInput">
						<el-select v-model="form.isSystemData" class="w-100">
							<el-option v-for="(v,i) in isSystemData" :key="i" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item v-if="$admin && form.isSystemData == 1" label="配置公司" class="formInput">
						<el-select v-model="form.companyId" class="w-100" filterable>
							<el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
						</el-select>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		company,
	} from '@/api'

	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					companyId: this.$admin ? '' : this.$companyId,
					penalty: '',
					isSystemData: '0',
					violateEvent: '',
					violateType: '',
					scoring: '0',
					punish: '',
					deductSafeKilometers: '0',
					accidentType: '',
				},
				company: [],
				serviceType: [],
				isSystemData: [],
				accidentTypeList: []
			}
		},
		created() {
			this.getCompany();
			this.getParentCode('VIOLATE_TYPE');
			this.getParentCode('TRUE_FALSE');
			this.getParentCode('ACCIDENT_TYPE');
		},
		mounted() {
			this.$nextTick(() => {
				this.upd()
			})
		},
		methods: {
			violateConfigChange() {
				this.form.accidentType = '';
			},
			inputClick1(e) {
				this.form.scoring = e.split('.')[0];
			},
			inputClick(e) {
				this.form.deductSafeKilometers = e.split('.')[0];
			},
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content
				})
			},
			getParentCode(id) {
				this.$axiosReq('/data/server/escort/web/dict/parentCode/' + id, null, null, 'get').then(res => {
					if (id == 'VIOLATE_TYPE') {
						this.serviceType = res.data
					} else if (id == 'TRUE_FALSE') {
						this.isSystemData = res.data
					} else if (id == 'ACCIDENT_TYPE') {
						this.accidentTypeList = res.data
					}
				})
			},
			upd() {
				if (this.info.addOr === 'upd') {
					this.form.companyId = this.row.companyId ? this.row.companyId : '';
					this.form.scoring = this.row.scoring ? this.row.scoring : '0';
					this.form.punish = this.row.punish ? this.row.punish : '';
					this.form.accidentType = this.row.accidentType ? this.row.accidentType : '';
					this.form.deductSafeKilometers = this.row.deductSafeKilometers ? this.row.deductSafeKilometers : '0';
					this.form.isSystemData = this.row.isSystemData ? this.row.isSystemData + '' : '';
					this.form.violateEvent = this.row.violateEvent ? this.row.violateEvent : '';
					this.form.violateType = this.row.violateType ? this.row.violateType : '';
					if (this.row.isSystemData === 0) {
						this.form.isSystemData = '0';
					}
				}
			},
			// 提交添加
			commit() {
				if (this.form.isSystemData != 1) {
					this.form.companyId = '';
				}
				if (!this.form.violateType) {
					this.$message.error('请选择违规类型！')
					return
				} else if (!this.form.accidentType && this.form.violateType == 1) {
					this.$message.error('请选择事故类型！')
					return
				} else if (!this.form.violateEvent) {
					this.$message.error('请输入违反事件！')
					return
				} else if (!this.form.isSystemData) {
					this.$message.error('请选择是否系统数据！')
					return
				}
				if (this.info.addOr === 'add') {
					this.$axiosReq('/archives/server/escort/web/violateConfig', this.form, null, 'post').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					})
				} else {
					this.$axiosReq('/archives/server/escort/web/violateConfig/' + this.row.id, this.form, null, 'put').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					})
				}
			},
			changeCompany(v) {
				console.log(v)
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>

</style>
