<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="150px" class="allForm">
					<el-form-item label="检查类型" :required="true">
						<el-input v-model="form.checkType" class="formInput" />
					</el-form-item>
					<el-form-item v-if="$admin" label="是否系统数据" :required="true" class="formInput">
						<el-select v-model="form.isSystemData" class="w-100">
							<el-option v-for="(v,i) in isSystemDataList" :key="i" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item v-if="$admin && form.isSystemData == 1" label="配置公司" :required="true" class="formInput">
						<el-select v-model="form.companyId" class="w-100" filterable>
							<el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
						</el-select>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		company,
	} from '@/api'

	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					companyId: this.$admin ? '' : this.$companyId,
					isSystemData: '0',
					checkType: '',
				},
				company: [],
				serviceType: [],
				isSystemDataList: [],
			}
		},
		created() {
			this.getCompany();
			this.getParentCode('TRUE_FALSE');
		},
		mounted() {
			this.$nextTick(() => {
				this.upd()
			})
		},
		methods: {
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content
				})
			},
			getParentCode(id) {
				this.$axiosReq('/data/server/escort/web/dict/parentCode/' + id, null, null, 'get').then(res => {
					this.isSystemDataList = res.data
				})
			},
			upd() {
				if (this.info.addOr === 'upd') {
					this.form.companyId = this.row.companyId ? this.row.companyId : '';
					this.form.isSystemData = this.row.isSystemData ? this.row.isSystemData + '' : '';
					this.form.checkType = this.row.checkType ? this.row.checkType : '';
					if (this.row.isSystemData === 0) {
						this.form.isSystemData = '0';
					}
				}
			},
			// 提交添加
			commit() {
				if (!this.form.checkType) {
					this.$message.error('请输入检查类型！')
					return
				} else if (!this.form.companyId && this.form.isSystemData == 1) {
					this.$message.error('请选择配置公司！')
					return
				}
				if (this.info.addOr === 'add') {
					this.$axiosReq('/archives/server/escort/web/physicalConditionConfig', this.form, null, 'post').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					})
				} else {
					this.$axiosReq('/archives/server/escort/web/physicalConditionConfig/' + this.row.id, this.form, null, 'put').then(res => {
						this.$message.success(res.msg)
						this.close()
						this.$emit('get-list')
					})
				}
			},
			changeCompany(v) {
				console.log(v)
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>

</style>
